import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "../../static/css/main.css";

class HiddenCase extends React.Component {
	componentDidMount() {
		grecaptcha.ready(() => {
			grecaptcha.execute("6LeFAqEUAAAAAOBIGFgU67742Rfbw83Yb-nM2_AJ", { action: "homepage" }).then((token) => {
				document.getElementById("captchaResponse").value = token;
			});
		});
	}

	render() {
		const { data } = this.props;
		const {
			title,
			description,
		} = data.hiddenCasesYaml;

		return (
			<div id="wrapper">
				<Helmet>
					<meta charset="utf-8" />
					<meta httpEquiv="x-ua-compatible" content="ie=edge" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					<title>entity</title>
					<script src="/js/jquery3.3.1-jquery.main.js" />
					<script src="https://www.google.com/recaptcha/api.js?render=6LeFAqEUAAAAAOBIGFgU67742Rfbw83Yb-nM2_AJ" />
				</Helmet>
				<header id="header">
					<div className="container-fluid">
						<strong className="logo">
							<a href="/">
								<img src="/images/logo.svg" width="94" alt="Entity" />
							</a>
						</strong>
						<a className="nav-opener" href="#"><span /></a>
						<div className="drop">
							<nav className="nav">
								<ul id="menu">
									<li><a href="/#services">services</a></li>
									<li className="active"><a href="/#cases">cases</a></li>
									<li><a href="/#ecosystem">ecosystem</a></li>
									<li><a href="/#team">team</a></li>
									<li><a href="/#jobs">jobs</a></li>
									<li><a className="anchor-link" href="#contact">kontakt</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</header>
				<main id="main">
					<strong className="logo hide">entity</strong>
					<div className="container container-lg">
						<ul className="breadcrumbs viewport move-effect">
							<li><a href="/#cases">cases</a></li>
						</ul>
					</div>
					<section className="case-section">
						<div className="container container-lg viewport move-effect">
							<h1 style={{ marginBottom: 24 }}>{title}</h1>
							<div>{description}</div>
							<div style={{ position: "relative" }}>
								<img
									src="/images/entity_invisible_case_bg.png"
									alt="hintergrund"
									style={{
										minWidth: 1000,
										height: "auto",
									}}
								/>
								<div
									style={{
										position: "absolute",
										display: "flex",
										left: 0,
										top: 0,
										width: "100%",
										height: "100%",
										alignItems: "center",
										justifyContent: "space-around",
									}}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}>
										<div
											style={{
												minHeight: 75,
												minWidth: 75,
												backgroundColor: "#F6F6F6",
												borderRadius: 37.5,
												display: "flex",
												alignItems: "center",
												justifyContent: "space-around",
												marginRight: 30,
											}}>
											<img src="/images/entity_hidden_content.svg" alt="schloss icon" />
										</div>
										<div>
											<div style={{ fontWeight: "500" }}>
												Informationen zu diesem Case sind auf Anfrage erhältlich.
											</div>
											<div>
												Dieser Case ist nicht öffentlich zugänglich.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="cookie-block viewport move-effect">
						<div className="container">
							<div className="text-block">
								<p>Diese Website nutzt ausschließlich technisch notwendige Cookies, um die bestmögliche Funktionalität bieten zu können.</p>
							</div>
							<div className="btn-holder">
								<a href="/privacyagreement">MEHR INFOS</a>
								<a href="#" className="btn btn-sm">OK</a>
							</div>
						</div>
					</div>
				</main>
				<footer id="footer">
					<div className="top-footer">
						<div className="container viewport" id="contact">
							<div className="form-block move-effect">
								<h2 className="h1">Spannender Case?<span>Hier Infos anfordern.</span></h2>
								<h2>Sendet uns eine Nachricht</h2>
								<form id="contactForm" method="POST" action="https://formcarry.com/s/fAlN_9DlyWw">
									<input type="text" placeholder="Vorname" name="firstName" />
									<input type="text" placeholder="Name*" name="lastName" required />
									<input type="text" placeholder="Firma" name="company" />
									<input type="email" placeholder="Ihre E-Mailadresse*" name="email" required />
									<textarea placeholder="Ihre Nachricht*" name="message" required />
									<div className="checkbox hide" id="checkboxContainer">
										<label htmlFor="check">
											<input id="check" type="checkbox" name="privacyTermsAccepted" />
											<span className="fake-input" />
											<span className="fake-label">Ich akzeptiere die <a href="/privacyagreement">Datenschutzerklärung</a> dieser Website.</span>
										</label>
									</div>
									<input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
									<button type="submit" className="btn btn-decor"><span>Senden</span></button>
								</form>
							</div>
							<div className="contact-block move-effect">
								<h2>entity</h2>
								<address>Schlesische Straße 29-30 Aufgang M, 2. OG <br /> 10997 Berlin</address>
								<ul className="info-list">
									<li><a className="tel" href="tel:+4930233264900">+49 30 23 32 649 00</a></li>
									<li><a href="mailto:&#105;&#110;&#102;&#111;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;">&#105;&#110;&#102;&#111;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;</a></li>
								</ul>
								<div className="map-block">
									<img src="/images/map.png" srcSet="/images/map-2x.png 2x" alt="" width="422" height="422" />
								</div>
								<a href="/impressum">Impressum</a>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

export default HiddenCase;

export const pageQuery = graphql`
	query($path: String!) {
		hiddenCasesYaml (path: { eq: $path }) {
			title
			description
  		}
	}
`;
